import axios from "axios";

const HOSTNAME = window.location.hostname;

class Editions {
  static setSelectEdition (Loader) {
    const select = document.getElementById("editions-select");

    if (select) {
      select.addEventListener("change", async ({ target }) => {
        const editionId = target.value;
        Editions.loadEdition(Loader, editionId);
      });
    }
  }

  static async loadEdition (Loader, editionId) {
    Loader.startLoading();

    const { data } = await Editions.getTemplateEdition(editionId);
    document.getElementById("current-edition-old").innerHTML = data;

    Loader.stopLoading();
  }

  static getTemplateEdition (editionId) {
    const editionResponseUrl = `${window.location.protocol}//${HOSTNAME}/edition-responsedev-page/?edition_id=${editionId}`;
    return axios.get(editionResponseUrl);
  }
}

export default Editions;
