import Editions from "./editions";
import Loader from "./loader";
import MenuEvents from "./menu-events";

// Load application
window.onload = () => {
  /**
   * Menu and Window events
   */
  MenuEvents.setBurgerMenuWhenMobile();
  MenuEvents.scrollPageEvent();
  MenuEvents.smoothLinkEvent();

  /**
   * Editions
   */
  Editions.setSelectEdition(Loader);

  // Setting default edition
  const editionId = document.getElementById("editions-select");

  if (editionId) Editions.loadEdition(Loader, editionId.value);
  // Loader stop if old edition is empty
  else Loader.stopLoading();
};
